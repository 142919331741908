export enum RoleName {
    TREAdmin = "TREAdmin",
    TREUser = "TREUser",
    ImperialTREAdmin = "ImperialTREAdmin"
}

export enum WorkspaceRoleName {
    WorkspaceOwner = "WorkspaceOwner",
    WorkspaceResearcher = "WorkspaceResearcher",
    AirlockManager = "AirlockManager",
    WorkspaceResearchLead = "WorkspaceResearchLead",
    WorkspaceDataEngineer = "WorkspaceDataEngineer"
}
